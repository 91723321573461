/* For radio button in registration page  */
window.onload = function(){
    $('.ibR_rad_sp').on('click' , (e) =>{
        let parent = $(e.target).closest('.ibR_reg-radio');
        var tar = $(e.target).attr('data');
        $('input.ibR_rad_in' , parent).attr('checked' , false);
        $('input[data=' + tar  + '].ibR_rad_in' , parent).attr('checked' , true);
        $('.ibR_rad_sp' , parent).removeClass('selected');
        $(e.target).addClass('selected');
    });
}

/*
$.ajax({ 
    type:'GET',
    url:"/api/get_stats",
    data:{

    '_token': $('meta[name="csrf-token"]').attr('content'),
    
    },
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
        'Authorization': 'Bearer ' + $('meta[name="api_token"]').attr('content'),
    },
    success:function(res){

    $('#count_translated_word').text(res.count_translated_word);
    $('#count_not_translated').text(res.count_not_translated);
    $('#cnt_open_course').text(res.cnt_open_course);
    $('#cnt_close_course').text(res.cnt_open_course);
    $('#cnt_duties').text(res.cnt_open_course);
    $('#cnt_delete_duties').text(res.cnt_open_course);
    $('#cnt_exams').text(res.cnt_open_course);
    $('#cnt_delete_exams').text(res.cnt_open_course);
    $('#cnt_certificate').text(res.cnt_open_course);
    $('#cnt_delete_certificate').text(res.cnt_open_course);
    $('#cnt_studyplan').text(res.cnt_open_course);
    $('#cnt_delete_studyplan').text(res.cnt_open_course);



    }

});
*/

$(document).on('click', '.edit_bio', function () {
    if ($('.desc_bio[contenteditable]').length == 0) {
        $('.desc_bio').attr('contenteditable', 'true');
        $('.desc_bio').focus();
        $('.edit_bio').html('<span>Save</span>');
    } else {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            url: '/edit_bio/' + $('#bio_user_id').val(),
            type: 'POST',
            dataType: 'JSON',
            data: {
                bio: $('.desc_bio').html()
            },
            success: function (data) {
                if (data.status) {
                    location.reload();
                } else {
                    alert("Something went wrong");
                }
            }
        });
    }
});

$(".management_tools").hide();
$(".toggle-admintools").on('click', function(){
    $("i", this).toggleClass("fa fa-angle-double-up fa fa-angle-double-down");
    $(".management_tools").toggle();
});

/* For Navigation in mobile  */
$(document).ready(function(){
    $(".ibR_nav_bar_mob ").click(function(){
      $(".navigation_header").toggle("slide");
    });
});
$(document).on('click','.cultural_event',function(){
    $(this).toggleClass('cultural_role');
});

$(document).on("mouseenter", ".ibR_btn_unfollow", function(e) {
   $(this).html('<span style="">Unfollow</span>');
});

$(document).on("mouseleave", ".ibR_btn_unfollow", function(e) {
     $(this).html('<span style="">Followed</span>');
});
$(document).on('click','#ibR_btn_profile_cancel',function(){
    $('#user_profile').find('.ibR_user_editable_ip').hide();
    $('.cover_img_profile').hide();
    $('#user_profile').find('.ibR_user_db_val').show();
});

/* Profile Page for edit  */

$("#ibR_btn_profile_edit").click(function(){
    
    $('.ibR_user-profile .ibR_user_db_val').hide();
    $('.ibR_user-profile .ibR_user_editable_ip').show();
    $('.cover_img_profile').show();

    
    if( $(".ibR_user_img_upload").length ){
        $('.ibR_user_img_upload').show()
    }
    if( $(".ibR_user_album_slider_edit").length){
        $('.ibR_user_album_slider_edit').show();
        $('.ibR_user_album_slider').hide();
    }
    
    $('.ib_required').show();
    $('#ibR_btn_profile_save').show();
    $([document.documentElement, document.body]).animate({
        scrollTop: $(".ibR_user-profile").offset().top
    }, 2000);
});

// function show_profile_picture(input){
//     if (input.files && input.files[0]) {
//        var reader = new FileReader();
//        reader.onload = function (e) {
//            $('#user_avatar').attr('src', e.target.result);
       
//        };
//        reader.readAsDataURL(input.files[0]);
//     }
// }

// function show_wall_image(input){
//     if (input.files && input.files[0]) {
//        var reader = new FileReader();
//        reader.onload = function (e) {
//            $('#wall-image').attr('src', e.target.result);
       
//        };
//        reader.readAsDataURL(input.files[0]);
//     }
// }

 var create_group = $('#ib_create_group');
    var create_group_close = $('#ib_create_group_close');
    var create_group_container = $('.ib_create_group_container');

    // Show create group form
    create_group.on('click' , () => {
        create_group_container.show();
    });

    // close create group form
    create_group_close.on('click' , () => {
        create_group_container.hide();
    });
    
    var create_member = $('#ib_create_member');
    var create_member_close = $('#ib_create_member_close');
    var create_member_container = $('.ib_create_member_container');

    // Show create group form
    create_member.on('click' , () => {
        create_member_container.show();
    });

    // close create group form
    create_member_close.on('click' , () => {
        create_member_container.hide();
    });
 window.removeProfileSliderimg = function(user_id,column_name,img_name,key){
    $.ajax({
        type:'get',
        url:'/delete_slider_profile',
        data:{
           img_name:img_name,
           column_name:column_name,
           user_id:user_id,
        },
        success:function(data){
            if(data.status==1){
              $("#prof_img_slider"+key).remove();
            }
        }
    }); 
}

/* Profile Page edit password  */

$('#icon_edit_password').click(function(){
    $('#profile_ip_password').attr("readonly", false);
});
$('#icon_edit_definition').click(function(){
    $('#profile_ip_definition').attr("readonly", false);
});
$(document).on('click','#icon_restore_definition',function(){  
     $.ajaxSetup({
        headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    $.ajax({
            url: '/restore_definition',
            type: 'POST',
            dataType: 'JSON',
            data: {
            user_id: $('#user_profile').find('[name="user_id"]').val()
            },
            success: function (data) {
            if (data.status) {
                $('#profile_ip_definition').val(data.data);
                $('.profile_ip_definition').text(data.data);
                successMessage('Default Definition restore successfully');
                $('.ibR_user-profile .ibR_user_db_val').show();
                $('.ibR_user-profile .ibR_user_editable_ip').hide();
                if ($(".ibR_user_img_upload").length) {
                    $('.ibR_user_img_upload').hide();
                }
                if ($(".ibR_user_album_slider_edit").length) {
                    $('.ibR_user_album_slider_edit').hide();
                    $('.ibR_user_album_slider').show();
                } 
                $('.ib_required').hide();
                $('#ibR_btn_profile_save').hide(); 
                
                if ($('#profile_ip_definition').val() == $('#hidden_group_definition').val()) {
                    $('#icon_restore_definition').hide(); 
                }
            } else {
                errorMessage("Something went wrong")
            }
        }
    });
})
/* Profile Page edit private definition */
$('#icon_edit_pvt_defn').click(function(){
    $('#profile_ip_pvt_defn').attr("readonly", false);
});

/* Profile Page reset private definition */
$('#icon_reset_pvt_defn').click(function(){
    $('#profile_ip_pvt_defn').val('');
});

/** Sidebar functions */
$('select').select2();


/* table toggle content */
$('.ib_table_button').on('click' , (event) => {
    let element = $(event.target);
    let id = element.attr('data-id');
    $('tr[data-id=' + id + '].ib_table_toggle_content').toggle();
});
window.goToLink = function(link){  
  window.location.href = link;
} 
/*Save Profile  */
window.saveProfileData = function(form_id){  
    $('#show_wait').show();

    var validate = validateProfileField(form_id);
        var data = $('#'+form_id).serializeArray();
        var address = $('#'+form_id).attr('action');
        $.post(address,data)
        .done( res =>{
			$('#show_wait').hide();
			$('#user_profile').find('.ibR_user_editable_ip').hide();
			$('.cover_img_profile').hide();
			$('#ibR_btn_profile_save').hide();
			$('.ibR_upload-btn-wall').hide();
			$('.ibR_user_img_upload').hide()
			$('#ibR_btn_profile_cancel_').hide();  
			
			 if ($('.wallImgCover').children('canvas').length > 0) {
				 var canvas = document.getElementById("wall-image");
                  var  ctx = canvas.getContext("2d");
                 var savedData = new Image();
				 savedData.src = canvas.toDataURL("image/png");
				 //
				
				 var image_holder = $('.wallImgCover')
				 $("<img />", {
					"src":  savedData.src,
					"class": "newimg-cover",
					"id":'wall-image',
					"alt":"default wall image" ,
					"data-cropzee":"wall_image"
				  }).appendTo(image_holder);
				  $('#wall-image').remove();
			 }
			  if ($('.avatarImgCover').children('canvas').length > 0) {
					var canvas_ = document.getElementById("top_avatarimage_");
					var  ctx_ = canvas_.getContext("2d");
					var savedData_ = new Image();
					savedData_.src = canvas_.toDataURL("image/png");
					var image_holder_ = $('.avatarImgCover');
					var image_holder_profile = $('.profile_image');
				   $("<img />", {
								"src": savedData_.src ,
								"class":"newimg-profile ibr_border custom_left",
								"id":'top_avatarimage_',
								"alt":"default Avatar" ,
								"data-cropzee":"top_avtar_image"
							  }).appendTo(image_holder_);
                 $('#top_avatarimage_').remove();
                 $('#user_avatar').remove();
		         $("<img />", {
						"src": savedData_.src,
						"class":"articles-header__img ibr_border",
						"id":'user_avatar',
						"alt":"default Avatar" ,
						"data-cropzee":"avtar_image"
					  }).appendTo(image_holder_profile);				 
			  }	
			  
			    if ($('.profile_image').children('canvas').length > 0) {
					var canvas_2 = document.getElementById("user_avatar");
					var  ctx_2 = canvas_2.getContext("2d");
					var savedData_2 = new Image();
					savedData_2.src = canvas_2.toDataURL("image/png");
					var image_holder_ = $('.avatarImgCover');
					var image_holder_profile = $('.profile_image');
				   $("<img />", {
								"src": savedData_2.src ,
								"class":"newimg-profile ibr_border custom_left",
								"id":'top_avatarimage_',
								"alt":"default Avatar" ,
								"data-cropzee":"top_avtar_image"
							  }).appendTo(image_holder_);
                 $('#top_avatarimage_').remove();
                 $('#user_avatar').remove();
		         $("<img />", {
						"src": savedData_2.src,
						"class":"articles-header__img ibr_border",
						"id":'user_avatar',
						"alt":"default Avatar" ,
						"data-cropzee":"avtar_image"
					  }).appendTo(image_holder_profile);				 
			  }	
			 
			 successMessage('Data Saved Sucessfully');
			if ($('#profile_ip_definition').val() !== $('#hidden_group_definition').val()) {
				$('#icon_restore_definition').show();
			}
        })
        .fail( err =>{ 
            errorMessage('Something went wrong');
        })
    
}


// function validateProfileField(form_id){
//     let valid = true;
//     $('.err_message').remove();
//     $('#' + form_id + ' input[data-valid]').each(function(){
//         if($(this).attr('data-valid') == 'false'){
//             $(this).addClass('err_inp');
//             $(this).after('<span class="err_message d-block">'+ $(this).attr('name') +' already exist!</span>');
//             valid = false;
//         }
//     });
//     $('#'+form_id).find('input').each(function(){
//         $(this).removeClass('err_inp');
//         if($(this).prop('required')){
//             if($(this).val() == ''){
//                 valid= false;
//                 $(this).addClass('err_inp');
//                 $(this).after('<span class="err_message d-block">Field is required</span>');
//             }
//         }
//     });
    
//     return valid;
// }

window.successMessage = function(msg){  
 $('#message_header').html(`<div class=" ibR_msg_bar fade show">
    <div class="alert alert-dismissable alert-success mb-0 p-0 successbar">
        <div class="iconblock bg-success"><img src="{{ asset(env('UPLOAD_FOLDER').'/icons/correct-icon.png') }}"></div>
        <div class="textblock">
            <strong>`+ msg +`</strong>
            <button type="button" class="btn close" 
                    data-dismiss="alert" aria-label="Close"> 
                <span aria-hidden="true">×</span> 
            </button>
        </div>
    </div> 
</div>`); 
    $([document.documentElement, document.body]).animate({
        scrollTop: $('body').offset().top
    }, 2000);
}
window.errorMessage = function(msg){  

  
  $('#message_header').html(`<div class="ibR_msg_bar fade show">
    <div class="alert alert-dismissable alert-danger mb-0 p-0 errorbar">
        <div class="iconblock bg-danger"><i class="fa fa-times-circle"></i></div>
        <div class="textblock">
            <strong>`+ msg +`</strong>
            <button type="button" class="btn close" 
                    data-dismiss="alert" aria-label="Close"> 
                <span aria-hidden="true">×</span> 
            </button>
        </div>
    </div> 
</div> `);
 
    $([document.documentElement, document.body]).animate({
        scrollTop: $('body').offset().top
    }, 2000);
}
window.errorMessage_ = function(msg){  

  
  $('#message_header').append(`<div class="ibR_msg_bar fade show">
    <div class="alert alert-dismissable alert-danger mb-0 p-0 errorbar">
        <div class="iconblock bg-danger"><i class="fa fa-times-circle"></i></div>
        <div class="textblock">
            <strong>`+ msg +`</strong>
            <button type="button" class="btn close" 
                    data-dismiss="alert" aria-label="Close"> 
                <span aria-hidden="true">×</span> 
            </button>
        </div>
    </div> 
</div> `);
 
    $([document.documentElement, document.body]).animate({
        scrollTop: $('body').offset().top
    }, 2000);
}
window.goBack = function(){  
  window.history.back();
}

window.ActiveMember = function(id,status,event,token){  
 $.ajax({
        type:'post',
        url:'/change-group-status',
        data:{
           id:id,
           status:status,
           '_token': token
        },
        success:function(data){
            data = JSON.parse(data)
            if(data.status==1){
              successMessage(data.message);
              if(status ==1){
                   $(event).parent().html(`<button type="button" class="ib_btn btn_active active_column" onclick="ActiveMember(`+ id +`,2,this,'`+ token +`')">Active</button>`);
                        
              }else{
                  $(event).parent().html(`<button type="button" class="btn_active active_column btn_danger" onclick="ActiveMember(`+ id +`,1,this,'`+ token +`')" >InActive</button>`);
              }
            }
        }
    });    
}

/* Sidebar toggle functionality */
$('.ib_sidebar_toggle').on('click' , (e) => {
    // $('.ib_sidebar').toggle();
     $(".ib_sidebar").animate({
      width: "toggle"
    });
    if( !$(e.currentTarget).hasClass('ib_sidebar_open') ){
        // $(e.currentTarget).css('left' , 'calc(1vw + 260px)');
        $(e.currentTarget).addClass('ib_sidebar_open');
        $('i' , e.currentTarget).addClass('fa-angle-double-left');
        $('i' , e.currentTarget).removeClass('fa-angle-double-right');
      
        $("#icon_sidebar").animate({
      width: "toggle"
    });
        // check if local storage is supported by the browser
        if (localStorage) {
            localStorage.sidebar = 'open';
        }
    }else{
        // $(e.currentTarget).css('left' , '1vw');
        $(e.currentTarget).removeClass('ib_sidebar_open');
        $('i' , e.currentTarget).addClass('fa-angle-double-right');
        $('i' , e.currentTarget).removeClass('fa-angle-double-left');
$("#icon_sidebar").animate({
      width: "toggle"
    });
        // check if local storage is supported by the browser
        if (localStorage) {
            localStorage.sidebar = 'close';
        }
    }
});

/* confirmation modal */
window.showConfirmationModel = function(url,msg) {    
    $('#confirm_message').empty();
    $("#confirm_redirect_link").attr("href",url);
    $('#confirm_message').append(msg);
    $('#confirmation_popup').modal('show');
}

/* Send request for deleting account */
window.sendRequestDeleteAccount = function(user_id) {    
    $('#requester_user_id').val(user_id);
    // $('#request_delete_account').modal('show');
    $('#delete_section').html($('#request_delete_account').html());
    $('.ib_account_control_submit').hide();
}
$(document).on('click','.clear_delete',function(){
    $('#delete_section').html('');
    $('.ib_account_control_submit').show();
});
window.validateEmail = function(email) {    
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// /*Validation user email and mobile from backend */
// function validateUserEmailPhone(column_name,elem,user_id){
//     var column_value = elem.value;
//     $('.err_message').remove();
//     $(elem).removeClass('err_inp');
//     $(elem).attr('data-valid' , 'true');
    
//     $.ajax({
//         type:'get',
//         url:'/validate-user-db-col',
//         data:{
//            column_name:column_name,
//            column_value:column_value,
//            user_id:user_id,
//         },
//         success:function(data){
//             if(data.status == 0){
//                $(elem).addClass('err_inp');
//                $(elem).after('<span class="err_message d-block">'+column_name+' already exist!</span>');
//                $(elem).attr('data-valid' , 'false');
//                console.log('already there'); 
//             }
//             // validating email regex
//             if(column_name == 'email'){
//                if (!validateEmail(column_value)) {
//                    $(elem).addClass('err_inp');
//                    $(elem).after('<span class="err_message d-block">'+column_name+' is not in valid formate!</span>');
//                    $(elem).attr('data-valid' , 'false');
//                } 
//             }
//         }
//     });      
// }

// Mobile Verification
window.mobileOtpVerification = function(user_id) {    
    $('#m_verify_user_id').val(user_id);
    $('#verify_mobile').modal('show');
}

/*Save form */
window.saveForm_ = function(form_id) {    
        var data = $('#'+form_id).serializeArray();
        console.log(data);
        var address = $('#'+form_id).attr('action');
        $.post(address,data)
        .done( res =>{
         console.log(res);
         $('#message_header').html(`<div class="ibR_msg_bar alert alert-dismissable alert-success fade show m-3">
                    <li style="padding:3px 14px;">
                <strong>Data Saved Sucessfully</strong>
                <button type="button" class="btn close" data-dismiss="alert" aria-label="Close"> 
                <span aria-hidden="true">×</span> 
                </button>
            </li>
            </div>`);
    $([document.documentElement, document.body]).animate({
        scrollTop: $('body').offset().top
    }, 2000);
        })
        .fail( err =>{ 
            console.log(err); 
    $('#message_header').html(`<div class="ibR_msg_bar alert alert-dismissable alert-danger fade show m-3">
                    <li style="padding:3px 14px;">
                <strong>Something went wrong</strong>
                <button type="button" class="btn close" data-dismiss="alert" aria-label="Close"> 
                <span aria-hidden="true">×</span> 
                </button>
            </li>
            </div>`);
   $([document.documentElement, document.body]).animate({
        scrollTop: $('body').offset().top
    }, 2000);
        })
    }


$(document).ready( function() {
    // check if local storage is supported by the browser
    if (localStorage) {
        if( localStorage.sidebar == 'open'){
            $('.ib_sidebar_toggle').click();
        }
    }
});
window.showConfirmationBox = function(link, text){
    $('html, body').animate({
        scrollTop: $(".confirmationbox").offset().top
    }, 2000);
    $('.confirmationbox').html('<div class="modal-dialog modal-lg ibR_modal ibr_border"> <div class="modal-content ibR_bg_light"><div class="modal-header"><h4 class="modal-title ibR_modal_title ibR_forecolor"><span style="">' + text + '</span></h4></div><div class="modal-footer text-center"><a href="' + link + '" class="ib_btn"><span style="">Yes</span></a><button type="button" onclick="$(\'.confirmationbox\').html(\'\')" class="ib_btn"><span style="">No</span></button></div></div></div>');
}


window.remove_data = function(module_type,remove){    
   console.log(module_type);
     var data_array = [];
     
     $("input[name='is_check']:checked").each(function () {
         var user_id = $(this).data('key');
         data_array.push(user_id);
     })
      if (data_array.length == 0)
     {
         errorMessage('please select atleast one '+module_type+'!');
     } else {
         showConfirmBox('Are you sure want to remove '+module_type+' permanently? ', () => {
             $.ajax({
                 headers: {
                     'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                 },
                 type: 'post',
                 url: '/remove_'+module_type,
                 data: {
                     data_array: data_array,
                 },
                 success: function (data) {
                     if (data.msg)
                     {
                         successMessage(data.msg);
                         window.location.reload();

                     }else if(data.eror){
                         errorMessage(data.eror);
                     } 
                     else {
                         errorMessage("Something went wrong")
                     }
                 }
             });
         })

     }
    console.log(data_array);
}
window.restore_data = function(module_type,restore){    

    console.log(module_type);
     var data_array = [];
     
     $("input[name='is_check']:checked").each(function () {
         var user_id = $(this).data('key');
         data_array.push(user_id);
     })
      if (data_array.length == 0)
     {
         errorMessage('please select atleast one '+module_type+'!');
     } else {
         showConfirmBox('Are you sure want to restore '+module_type+'? ', () => {
             $.ajax({
                 headers: {
                     'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                 },
                 type: 'post',
                 url: '/restore_'+module_type,
                 data: {
                     data_array: data_array,
                 },
                 success: function (data) {
                     if (data.msg)
                     {
                         successMessage(data.msg);
                         window.location.reload();

                     }else if(data.eror){
                         errorMessage(data.eror);
                     } 
                     else {
                         errorMessage("Something went wrong")
                     }
                 }
             });
         })

     }
    console.log(data_array);
}
	
			
			
/*Save form */
window.saveForm = function(form_id){

    var data = $('#'+form_id).serializeArray();
    console.log(data);
	$('.loader').show();
    var address = $('#'+form_id).attr('action');
    $.post(address,data)
    .done( res =>{
$('.loader').hide();
successMessage('Data Saved Sucessfully');
    
$([document.documentElement, document.body]).animate({
    scrollTop: $('body').offset().top
}, 2000);
    })
    .fail( err =>{ 
//            console.log(err); 
         errorMessage('Something went wrong');
$([document.documentElement, document.body]).animate({
    scrollTop: $('body').offset().top
}, 2000);
    })
}
window.deleteForm = function(text) {
    console.log(text);
    if (text == "unhide")
    {
         var user_array = [];
         $("input[name='is_hide_check']:checked").each(function () {
            var user_id = $(this).data('key');
            user_array.push(user_id);
        })

        if (user_array.length == 0)
            {
                errorMessage('please select atleast one user!');
            } else {
                showConfirmBox_delete('Are you sure want to delete(..) users? ', () => {
                    $('input[type=radio][name="user_delete"]:checked').val();
                    var radio_id = $('input[type=radio][name="user_delete"]:checked').val();
                    if(radio_id == undefined)
                    {
                    }else{
                        $('.error_after').find('p').remove();
                    $.ajax({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        type: 'post',
                        url: '/delete_user_data',
                        data: {
                            user_array: user_array,
                            radio_id: radio_id,
                        },
                        success: function (data) {
                            if (data.msg)
                            {
                                successMessage(data.msg);
                                window.location.reload();

                            } else {
                                errorMessage("Something went wrong")
                            }
                        }
                    });
                    }
                })
            }
        
    }else{
            var user_array = [];
            $("input[name='is_check']:checked").each(function () {
                var user_id = $(this).data('key');
                user_array.push(user_id);
            })
        console.log(user_array);
            if (user_array.length == 0)
            {
                errorMessage('please select atleast one user!');
            } else {
                showConfirmBox_delete('Are you sure want to delete(..) users? ', () => {
                    $('input[type=radio][name="user_delete"]:checked').val();
                    console.log($('input[type=radio][name="user_delete"]:checked').val());
                    var radio_id = $('input[type=radio][name="user_delete"]:checked').val();
                                
                    if(radio_id == undefined)
                    {
                    }else{
                        $('.error_after').find('p').remove();
        
                    $.ajax({
                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                        type: 'post',
                        url: '/delete_user_data',
                        data: {
                            user_array: user_array,
                            radio_id: radio_id,
                        },
                        success: function (data) {
                            if (data.msg)
                            {
                                successMessage(data.msg);
                                window.location.reload();
                    
                            } else {
                                errorMessage("Something went wrong")
                            }
                        }
                    });
            }
                })
            }
        }
    }
window.hideForm = function(text) {    
    if (text == "hide")
    {
        var user_array = [];
        $("input[name='is_check']:checked").each(function () {
            var user_id = $(this).data('key');
            user_array.push(user_id);
        })
                        
        if (user_array.length == 0)
        {
            errorMessage('please select atleast one user!');
        } else {
            showConfirmBox('Are you sure want to hide users? ', () => {
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    type: 'post',
                    url: '/hide_user_data',
                    data: {
                        user_array: user_array,
                        ck_text: 1,
                    },
                    success: function (data) {
                        if (data.msg)
                        {
                            successMessage(data.msg);
                            window.location.reload();
                                
                        } else {
                            errorMessage("Something went wrong")
                        }
                    }
                });
            })
        
        }
    } else {
        var user_array = [];
        $("input[name='is_hide_check']:checked").each(function () {
            var user_id = $(this).data('key');
            user_array.push(user_id);
        })
                            
        if (user_array.length == 0)
        {
            errorMessage('please select atleast one user!');
        } else {
            showConfirmBox('Are you sure want to unhide users? ', () => {
                $.ajax({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    type: 'post',
                    url: '/hide_user_data',
                    data: {
                        user_array: user_array,
                         ck_text: 2,
                    },
                    success: function (data) {
                        if (data.msg)
                        {
                            successMessage(data.msg);
                            window.location.reload();
            
                        } else {
                            errorMessage("Something went wrong")
                        }
                    }
                });
            })
        
        }
                            
    }
}